/* eslint-disable multiline-ternary */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {} from 'react-redux'

// ** Uniqid Import
import uniqid from 'uniqid'

// ** Axios Imports
// import axios from 'axios'

import {
  getOption,
  updateOption,
  addPost,
  updatePost,
  getPost,
  deletePost,
  getAllCptRequest,
  sortByKey,
  uniqValuesToOptions,
  getAbsences
} from '@utils'

export const getAllData = createAsyncThunk(
  'appCalendrier/getAllData',
  async () => {
    let getlistEvents = await getAllCptRequest('events')
    const getlistMemos = await getAllCptRequest('memos')
    let getlistChantiers = await getAllCptRequest('chantiers')
    let getlistEmployes = await getAllCptRequest('employes')
    let getlistMateriels = await getAllCptRequest('materiels')
    let getlistEquipes = await getAllCptRequest('equipes')

    getlistEvents = sortByKey(getlistEvents, 'jour', 'asc')
    getlistChantiers = sortByKey(getlistChantiers, 'client', 'asc')
    getlistEmployes = sortByKey(getlistEmployes, 'nom', 'asc')
    getlistMateriels = sortByKey(getlistMateriels, 'libelle', 'asc')
    getlistEquipes = sortByKey(getlistEquipes, 'title', 'asc')

    const naturesColors = await getOption({ key: 'naturescolors' })
    const statutsColors = await getOption({ key: 'statutscolors' })

    return {
      events: getlistEvents,
      memos: getlistMemos,
      chantiers: getlistChantiers,
      employes: getlistEmployes,
      materiels: getlistMateriels,
      equipes: getlistEquipes,
      naturesColors,
      statutsColors
    }
  }
)

export const getEvent = createAsyncThunk(
  'appCalendrier/getEvent',
  async (id) => {
    const args = {
      id: parseInt(id),
      cpt: 'events'
    }

    const post = await getPost(args)

    return post
  }
)

export const getChantier = createAsyncThunk(
  'appCalendrier/getChantier',
  async (id) => {
    const args = {
      id: parseInt(id),
      cpt: 'chantiers'
    }

    const post = await getPost(args)

    return post
  }
)
export const getEmploye = createAsyncThunk(
  'appCalendrier/getEmploye',
  async (id) => {
    const args = {
      id: parseInt(id),
      cpt: 'employes'
    }

    const post = await getPost(args)

    return post
  }
)
export const getMateriel = createAsyncThunk(
  'appCalendrier/getMateriel',
  async (id) => {
    const args = {
      id: parseInt(id),
      cpt: 'materiels'
    }

    const post = await getPost(args)

    return post
  }
)

export const updateAppOption = createAsyncThunk(
  'appCalendrier/updateAppOption',
  async (params) => {
    const { key } = params
    const option = await updateOption(params)

    return {
      key,
      val: option
    }
  }
)

export const getAppOption = createAsyncThunk(
  'appCalendrier/getAppOption',
  async (params) => {
    const { key } = params
    const option = await getOption(params)

    return {
      key,
      val: option
    }
  }
)

export const addEvent = createAsyncThunk(
  'appCalendrier/addEvent',
  async (params) => {
    const { newEvent } = params
    const cpt = 'events'

    const args = {
      post: newEvent,
      cpt
    }

    const newPost = await addPost(args)

    return newPost
  }
)

export const addMemo = createAsyncThunk(
  'appCalendrier/addMemo',
  async (params) => {
    const { newMemo } = params
    const cpt = 'memos'

    const args = {
      post: newMemo,
      cpt
    }

    console.log(args)

    const newPost = await addPost(args)

    return newPost
  }
)

export const addEquipe = createAsyncThunk(
  'appCalendrier/addEquipe',
  async (params) => {
    const { newEquipe } = params
    const cpt = 'equipes'

    const args = {
      post: newEquipe,
      cpt
    }
    const newPost = await addPost(args)

    return newPost
  }
)
export const addEmploye = createAsyncThunk(
  'appCalendrier/addEmploye',
  async (params) => {
    const { newEmploye } = params
    const cpt = 'employes'

    const args = {
      post: newEmploye,
      cpt
    }
    const newPost = await addPost(args)

    return newPost
  }
)
export const addMateriel = createAsyncThunk(
  'appCalendrier/addMateriel',
  async (params) => {
    const { newMateriel } = params
    const cpt = 'materiels'

    const args = {
      post: newMateriel,
      cpt
    }
    const newPost = await addPost(args)

    return newPost
  }
)
export const addChantier = createAsyncThunk(
  'appCalendrier/addChantier',
  async (params) => {
    const { newChantier } = params
    const cpt = 'chantiers'

    const args = {
      post: newChantier,
      cpt
    }
    const newPost = await addPost(args)

    return newPost
  }
)

export const updateChantier = createAsyncThunk(
  'appCalendrier/updateChantier',
  async (params) => {
    const { editChantier, id } = params
    const cpt = 'chantiers'
    const args = {
      id: parseInt(id),
      post: editChantier,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const updateEvent = createAsyncThunk(
  'appCalendrier/updateEvent',
  async (params) => {
    const { editEvent, id } = params
    const cpt = 'events'

    const args = {
      id: parseInt(id),
      post: editEvent,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const updateMemo = createAsyncThunk(
  'appCalendrier/updateMemo',
  async (params) => {
    const { editMemo, id } = params
    const cpt = 'memos'

    const args = {
      id: parseInt(id),
      post: editMemo,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const updateEmploye = createAsyncThunk(
  'appCalendrier/updateEmploye',
  async (params) => {
    const { editEmploye, id } = params
    const cpt = 'employes'

    const args = {
      id: parseInt(id),
      post: editEmploye,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)
export const updateMateriel = createAsyncThunk(
  'appCalendrier/updateMateriel',
  async (params) => {
    const { editMateriel, id } = params
    const cpt = 'materiels'

    const args = {
      id: parseInt(id),
      post: editMateriel,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)
export const updateEquipe = createAsyncThunk(
  'appCalendrier/updateEquipe',
  async (params) => {
    const { editEquipe, id } = params
    const cpt = 'equipes'

    const args = {
      id: parseInt(id),
      post: editEquipe,
      cpt
    }

    const updatedPost = await updatePost(args)

    return updatedPost
  }
)

export const deleteEvent = createAsyncThunk(
  'appCalendrier/deleteEvent',
  async (id) => {
    const cpt = 'events'
    const args = {
      id: parseInt(id),
      cpt
    }
    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const deleteMemo = createAsyncThunk(
  'appCalendrier/deleteMemo',
  async (id) => {
    const cpt = 'memos'
    const args = {
      id: parseInt(id),
      cpt
    }
    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const deleteEquipe = createAsyncThunk(
  'appCalendrier/deleteEquipe',
  async (id) => {
    const cpt = 'equipes'
    const args = {
      id: parseInt(id),
      cpt
    }
    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const deleteEmploye = createAsyncThunk(
  'appCalendrier/deleteEmploye',
  async (id) => {
    const cpt = 'employes'
    const args = {
      id: parseInt(id),
      cpt
    }
    const deletedPost = await deletePost(args)

    return deletedPost
  }
)
export const deleteMateriel = createAsyncThunk(
  'appCalendrier/deleteMateriel',
  async (id) => {
    const cpt = 'materiels'
    const args = {
      id: parseInt(id),
      cpt
    }
    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const deleteChantier = createAsyncThunk(
  'appCalendrier/deleteChantier',
  async (id) => {
    const cpt = 'chantiers'
    const args = {
      id: parseInt(id),
      cpt
    }
    const deletedPost = await deletePost(args)

    return deletedPost
  }
)

export const appCalendrierSlice = createSlice({
  name: 'appCalendrier',
  initialState: {
    uniqUser: uniqid(),
    firstLoad: true,
    selectedEvent: null,
    selectedChantier: null,
    selectedEmploye: null,
    selectedMateriel: null,
    dateRepere: new Date(),
    events: [],
    employes: [],
    equipes: [],
    chantiers: [],
    materiels: [],
    memos: [],
    absencesEmployes: [],
    absencesMateriels: [],
    chantiersOptions: [],
    employesOptions: [],
    equipesOptions: [],
    materielsOptions: [],
    naturesOptions: [],
    etatsOptions: [],
    statutsOptions: [],
    categoriesOptions: [],
    naturescolors: [],
    statutscolors: [],
    socket: 'Socket vide'
  },
  reducers: {
    reloadAllData(state) {
      state.firstLoad = true
    },
    registerSocket(state, action) {
      state.socket = action.payload
    },
    socketAddPost(state, action) {
      const post = action.payload
      const cpt = post.cpt
      const allPosts = state[cpt]

      const existPost =
        allPosts.filter((item) => item.id === post.id).length > 0

      if (!existPost) {
        let addPost = post

        switch (cpt) {
          case 'events':
            addPost = {
              ...post,
              employes: post.employes ? JSON.parse(post.employes) : [],
              materiels: post.materiels ? JSON.parse(post.materiels) : [],
              heures_employes: post.heures_employes
                ? JSON.parse(post.heures_employes)
                : {}
            }
            break

          case 'memos':
            addPost = {
              ...post
            }
            break

          case 'employes':
            addPost = {
              ...post,
              absences: post.absences ? JSON.parse(post.absences) : []
            }
            break

          case 'materiels':
            addPost = {
              ...post,
              absences: post.absences ? JSON.parse(post.absences) : []
            }
            break

          case 'equipes':
            addPost = {
              ...post,
              employes: post.employes ? JSON.parse(post.employes) : [],
              couleur: post.couleur ? JSON.parse(post.couleur) : ''
            }
            break
        }

        allPosts.push(addPost)
        state[cpt] = allPosts

        switch (cpt) {
          case 'events':
            const getlistStatuts = uniqValuesToOptions(allPosts, 'statut')
            state.statutsOptions = getlistStatuts
            break

          case 'chantiers':
            const chantiersOptions = allPosts.map((chantier) => {
              const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
              return { value: chantier.id, label }
            })
            state.chantiersOptions = chantiersOptions
            const getlistNatures = uniqValuesToOptions(allPosts, 'nature')
            const getlistEtats = uniqValuesToOptions(allPosts, 'etat')
            const getlistClients = uniqValuesToOptions(allPosts, 'client')
            state.naturesOptions = getlistNatures
            state.etatsOptions = getlistEtats
            state.clientsOptions = getlistClients
            break

          case 'employes':
            const employesOptions = allPosts.map((employe) => {
              const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
              return { value: employe.id, label }
            })
            state.employesOptions = employesOptions

            state.absencesEmployes = getAbsences(allPosts)
            break

          case 'materiels':
            const getlistCategories = uniqValuesToOptions(allPosts, 'categorie')

            state.categoriesOptions = getlistCategories

            const materielsOptions = allPosts.map((materiel) => {
              const label = `${materiel.libelle} (${materiel.num_inventaire})`
              return { value: materiel.id, label }
            })
            state.materielsOptions = materielsOptions

            state.absencesMateriels = getAbsences(allPosts)
            break

          case 'equipes':
            const equipesOptions = allPosts.map((equipe) => {
              const label = equipe.title
              return { value: equipe.id, label }
            })
            state.equipesOptions = equipesOptions
            break
        }
      }
    },
    socketUpdatePost(state, action) {
      const post = action.payload
      const cpt = post.cpt
      const allPosts = state[cpt]

      let updatedPost = post

      switch (cpt) {
        case 'events':
          updatedPost = {
            ...post,
            employes: post.employes ? JSON.parse(post.employes) : [],
            materiels: post.materiels ? JSON.parse(post.materiels) : [],
            heures_employes: post.heures_employes
              ? JSON.parse(post.heures_employes)
              : {}
          }
          break

        case 'memos':
          updatedPost = {
            ...post
          }
          break
        case 'employes':
          updatedPost = {
            ...post,
            absences: post.absences ? JSON.parse(post.absences) : []
          }
          break

        case 'materiels':
          updatedPost = {
            ...post,
            absences: post.absences ? JSON.parse(post.absences) : []
          }
          break

        case 'equipes':
          updatedPost = {
            ...post,
            employes: post.employes ? JSON.parse(post.employes) : [],
            couleur: post.couleur ? JSON.parse(post.couleur) : ''
          }
          break
      }

      const newAllPosts = allPosts.map((item) => {
        return parseInt(item.id) === parseInt(updatedPost.id)
          ? updatedPost
          : item
      })

      state[cpt] = newAllPosts

      switch (cpt) {
        case 'events':
          const getlistStatuts = uniqValuesToOptions(newAllPosts, 'statut')
          state.statutsOptions = getlistStatuts
          break

        case 'chantiers':
          const chantiersOptions = newAllPosts.map((chantier) => {
            const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
            return { value: chantier.id, label }
          })
          state.chantiersOptions = chantiersOptions
          const getlistNatures = uniqValuesToOptions(newAllPosts, 'nature')
          const getlistEtats = uniqValuesToOptions(newAllPosts, 'etat')
          const getlistClients = uniqValuesToOptions(newAllPosts, 'client')
          state.naturesOptions = getlistNatures
          state.etatsOptions = getlistEtats
          state.clientsOptions = getlistClients
          break

        case 'employes':
          const employesOptions = newAllPosts.map((employe) => {
            const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
            return { value: employe.id, label }
          })
          state.employesOptions = employesOptions

          state.absencesEmployes = getAbsences(newAllPosts)
          break

        case 'materiels':
          const getlistCategories = uniqValuesToOptions(
            newAllPosts,
            'categorie'
          )

          state.categoriesOptions = getlistCategories

          const materielsOptions = newAllPosts.map((materiel) => {
            const label = `${materiel.libelle} (${materiel.num_inventaire})`
            return { value: materiel.id, label }
          })
          state.materielsOptions = materielsOptions

          state.absencesMateriels = getAbsences(newAllPosts)
          break

        case 'equipes':
          const equipesOptions = newAllPosts.map((equipe) => {
            const label = equipe.title
            return { value: equipe.id, label }
          })
          state.equipesOptions = equipesOptions
          break
      }
    },
    socketDeletePost(state, action) {
      const post = action.payload
      const cpt = post.cpt
      const allPosts = state[cpt]
      const newAllPosts = allPosts.filter((item) => item.id !== post.id)

      state[cpt] = newAllPosts

      switch (cpt) {
        case 'events':
          const getlistStatuts = uniqValuesToOptions(newAllPosts, 'statut')
          state.statutsOptions = getlistStatuts
          break

        case 'chantiers':
          const chantiersOptions = newAllPosts.map((chantier) => {
            const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
            return { value: chantier.id, label }
          })
          state.chantiersOptions = chantiersOptions
          const getlistNatures = uniqValuesToOptions(newAllPosts, 'nature')
          const getlistEtats = uniqValuesToOptions(newAllPosts, 'etat')
          const getlistClients = uniqValuesToOptions(newAllPosts, 'client')
          state.naturesOptions = getlistNatures
          state.etatsOptions = getlistEtats
          state.clientsOptions = getlistClients
          break

        case 'employes':
          const employesOptions = newAllPosts.map((employe) => {
            const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
            return { value: employe.id, label }
          })
          state.employesOptions = employesOptions

          state.absencesEmployes = getAbsences(newAllPosts)
          break

        case 'materiels':
          const getlistCategories = uniqValuesToOptions(
            newAllPosts,
            'categorie'
          )

          state.categoriesOptions = getlistCategories

          const materielsOptions = newAllPosts.map((materiel) => {
            const label = `${materiel.libelle} (${materiel.num_inventaire})`
            return { value: materiel.id, label }
          })
          state.materielsOptions = materielsOptions

          state.absencesMateriels = getAbsences(newAllPosts)
          break

        case 'equipes':
          const equipesOptions = newAllPosts.map((equipe) => {
            const label = equipe.title
            return { value: equipe.id, label }
          })
          state.equipesOptions = equipesOptions
          break
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.events = action.payload.events

        state.memos = action.payload.memos

        state.employes = action.payload.employes
        state.chantiers = action.payload.chantiers
        state.materiels = action.payload.materiels
        state.equipes = action.payload.equipes

        state.absencesEmployes = getAbsences(action.payload.employes)
        state.absencesMateriels = getAbsences(action.payload.materiels)

        state.naturescolors = action.payload.naturesColors
          ? action.payload.naturesColors
          : []
        state.statutscolors = action.payload.statutsColors
          ? action.payload.statutsColors
          : []

        const chantiersOptions = action.payload.chantiers.map((chantier) => {
          const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
          return { value: chantier.id, label }
        })
        state.chantiersOptions = chantiersOptions
        const getlistNatures = uniqValuesToOptions(
          action.payload.chantiers,
          'nature'
        )
        const getlistEtats = uniqValuesToOptions(
          action.payload.chantiers,
          'etat'
        )
        const getlistClients = uniqValuesToOptions(
          action.payload.chantiers,
          'client'
        )
        const getlistStatuts = uniqValuesToOptions(
          action.payload.events,
          'statut'
        )
        state.statutsOptions = getlistStatuts

        state.naturesOptions = getlistNatures
        state.etatsOptions = getlistEtats
        state.clientsOptions = getlistClients

        const employesOptions = action.payload.employes.map((employe) => {
          const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
          return { value: employe.id, label }
        })
        state.employesOptions = employesOptions

        const equipesOptions = action.payload.equipes.map((equipe) => {
          const label = equipe.title
          return { value: equipe.id, label }
        })
        state.equipesOptions = equipesOptions

        const materielsOptions = action.payload.materiels.map((materiel) => {
          const label = `${materiel.libelle} (${materiel.num_inventaire})`
          return { value: materiel.id, label }
        })
        state.materielsOptions = materielsOptions

        const getlistCategories = uniqValuesToOptions(
          action.payload.materiels,
          'categorie'
        )
        state.categoriesOptions = getlistCategories

        state.firstLoad = false
      })
      .addCase(getAllData.rejected, (state, action) => {
        console.log('Erreur getAllDAta')
        console.log(action)
      })
      .addCase(updateAppOption.fulfilled, (state, action) => {
        state[action.payload.key] = action.payload.val
      })
      .addCase(getAppOption.fulfilled, (state, action) => {
        state[action.payload.key] = action.payload.val
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        const allEvents = state.events

        const addPost = {
          ...action.payload,
          employes: action.payload.employes
            ? JSON.parse(action.payload.employes)
            : [],
          materiels: action.payload.materiels
            ? JSON.parse(action.payload.materiels)
            : [],
          heures_employes: action.payload.heures_employes
            ? JSON.parse(action.payload.heures_employes)
            : {}
        }

        allEvents.push(addPost)
        state.events = allEvents

        const getlistStatuts = uniqValuesToOptions(allEvents, 'statut')
        state.statutsOptions = getlistStatuts
      })
      .addCase(addEvent.rejected, (state, action) => {
        console.log('Erreur addEvent')
        console.log(action)
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        const allEvents = state.events

        const updatedPost = {
          ...action.payload,
          employes: action.payload.employes
            ? JSON.parse(action.payload.employes)
            : [],
          materiels: action.payload.materiels
            ? JSON.parse(action.payload.materiels)
            : [],
          heures_employes: action.payload.heures_employes
            ? JSON.parse(action.payload.heures_employes)
            : {}
        }

        const newAllEvents = allEvents.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.events = newAllEvents

        const getlistStatuts = uniqValuesToOptions(newAllEvents, 'statut')
        state.statutsOptions = getlistStatuts
      })
      .addCase(updateEvent.rejected, (state, action) => {
        console.log('Erreur updateEvent')
        console.log(action)
      })
      .addCase(addChantier.fulfilled, (state, action) => {
        const allChantiers = state.chantiers
        allChantiers.push(action.payload)
        state.chantiers = allChantiers

        const chantiersOptions = allChantiers.map((chantier) => {
          const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
          return { value: chantier.id, label }
        })
        state.chantiersOptions = chantiersOptions
        const getlistNatures = uniqValuesToOptions(allChantiers, 'nature')
        const getlistEtats = uniqValuesToOptions(allChantiers, 'etat')
        const getlistClients = uniqValuesToOptions(allChantiers, 'client')
        state.naturesOptions = getlistNatures
        state.etatsOptions = getlistEtats
        state.clientsOptions = getlistClients
      })
      .addCase(addChantier.rejected, (state, action) => {
        console.log('Erreur addChantier')
        console.log(action)
      })
      .addCase(updateChantier.fulfilled, (state, action) => {
        const allChantiers = state.chantiers
        const updatedPost = action.payload
        const newAllChantiers = allChantiers.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })
        state.chantiers = newAllChantiers

        const chantiersOptions = newAllChantiers.map((chantier) => {
          const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
          return { value: chantier.id, label }
        })
        state.chantiersOptions = chantiersOptions
        const getlistNatures = uniqValuesToOptions(newAllChantiers, 'nature')
        const getlistEtats = uniqValuesToOptions(newAllChantiers, 'etat')
        const getlistClients = uniqValuesToOptions(newAllChantiers, 'client')
        state.naturesOptions = getlistNatures
        state.etatsOptions = getlistEtats
        state.clientsOptions = getlistClients
      })
      .addCase(updateChantier.rejected, (state, action) => {
        console.log('Erreur updateChantier')
        console.log(action)
      })
      .addCase(addEquipe.fulfilled, (state, action) => {
        const allEquipes = state.equipes

        const addPost = {
          ...action.payload,
          employes: action.payload.employes
            ? JSON.parse(action.payload.employes)
            : [],
          couleur: action.payload.couleur
            ? JSON.parse(action.payload.couleur)
            : ''
        }

        allEquipes.push(addPost)
        state.equipes = allEquipes

        const equipesOptions = allEquipes.map((equipe) => {
          const label = equipe.title
          return { value: equipe.id, label }
        })
        state.equipesOptions = equipesOptions
      })
      .addCase(addEquipe.rejected, (state, action) => {
        console.log('Erreur addEquipe')
        console.log(action)
      })
      .addCase(updateEquipe.fulfilled, (state, action) => {
        const allEquipes = state.equipes

        const updatedPost = {
          ...action.payload,
          employes: action.payload.employes
            ? JSON.parse(action.payload.employes)
            : [],
          couleur: action.payload.couleur
            ? JSON.parse(action.payload.couleur)
            : ''
        }
        const newAllEquipes = allEquipes.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.equipes = newAllEquipes

        const equipesOptions = newAllEquipes.map((equipe) => {
          const label = equipe.title
          return { value: equipe.id, label }
        })
        state.equipesOptions = equipesOptions
      })
      .addCase(updateEquipe.rejected, (state, action) => {
        console.log('Erreur updateEquipe')
        console.log(action)
      })
      .addCase(addEmploye.fulfilled, (state, action) => {
        const allEmployes = state.employes
        const addPost = {
          ...action.payload,
          absences: action.payload.absences
            ? JSON.parse(action.payload.absences)
            : []
        }
        allEmployes.push(addPost)
        state.employes = allEmployes

        const employesOptions = allEmployes.map((employe) => {
          const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
          return { value: employe.id, label }
        })
        state.employesOptions = employesOptions

        state.absencesEmployes = getAbsences(allEmployes)
      })
      .addCase(addEmploye.rejected, (state, action) => {
        console.log('Erreur addEmploye')
        console.log(action)
      })
      .addCase(updateEmploye.fulfilled, (state, action) => {
        const allEmployes = state.employes
        const updatedPost = {
          ...action.payload,
          absences: action.payload.absences
            ? JSON.parse(action.payload.absences)
            : []
        }

        const newAllEmployes = allEmployes.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.employes = newAllEmployes

        const employesOptions = newAllEmployes.map((employe) => {
          const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
          return { value: employe.id, label }
        })
        state.employesOptions = employesOptions

        state.absencesEmployes = getAbsences(newAllEmployes)
      })
      .addCase(updateEmploye.rejected, (state, action) => {
        console.log('Erreur updateEmploye')
        console.log(action)
      })
      .addCase(addMateriel.fulfilled, (state, action) => {
        const allMateriels = state.materiels
        const addPost = {
          ...action.payload,
          absences: action.payload.absences
            ? JSON.parse(action.payload.absences)
            : []
        }
        allMateriels.push(addPost)
        state.materiels = allMateriels

        const getlistCategories = uniqValuesToOptions(allMateriels, 'categorie')

        state.categoriesOptions = getlistCategories

        const materielsOptions = allMateriels.map((materiel) => {
          const label = `${materiel.libelle} (${materiel.num_inventaire})`
          return { value: materiel.id, label }
        })
        state.materielsOptions = materielsOptions

        state.absencesMateriels = getAbsences(allMateriels)
      })
      .addCase(addMateriel.rejected, (state, action) => {
        console.log('Erreur addMateriel')
        console.log(action)
      })
      .addCase(updateMateriel.fulfilled, (state, action) => {
        const allMateriels = state.materiels
        const updatedPost = {
          ...action.payload,
          absences: action.payload.absences
            ? JSON.parse(action.payload.absences)
            : []
        }
        const newAllMateriels = allMateriels.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })
        state.materiels = newAllMateriels

        const getlistCategories = uniqValuesToOptions(
          newAllMateriels,
          'categorie'
        )

        state.categoriesOptions = getlistCategories

        const materielsOptions = newAllMateriels.map((materiel) => {
          const label = `${materiel.libelle} (${materiel.num_inventaire})`
          return { value: materiel.id, label }
        })
        state.materielsOptions = materielsOptions

        state.absencesMateriels = getAbsences(newAllMateriels)
      })
      .addCase(updateMateriel.rejected, (state, action) => {
        console.log('Erreur updateMateriel')
        console.log(action)
      })
      .addCase(addMemo.fulfilled, (state, action) => {
        const allMemos = state.memos

        const addPost = {
          ...action.payload
        }

        allMemos.push(addPost)
        state.memos = allMemos
      })
      .addCase(addMemo.rejected, (state, action) => {
        console.log('Erreur addMemo')
        console.log(action)
      })
      .addCase(updateMemo.fulfilled, (state, action) => {
        const allMemos = state.memos

        const updatedPost = {
          ...action.payload
        }
        const newAllMemos = allMemos.map((post) => {
          return parseInt(post.id) === parseInt(updatedPost.id)
            ? updatedPost
            : post
        })

        state.memos = newAllMemos
      })
      .addCase(updateMemo.rejected, (state, action) => {
        console.log('Erreur updateEquipe')
        console.log(action)
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        const allEvents = state.events
        const newAllEvents = allEvents.filter(
          (item) => item.id !== action.payload.id
        )

        state.events = newAllEvents

        const getlistStatuts = uniqValuesToOptions(newAllEvents, 'statut')
        state.statutsOptions = getlistStatuts
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        console.log('Erreur deleteEvent')
        console.log(action)
      })
      .addCase(deleteChantier.fulfilled, (state, action) => {
        const allChantiers = state.chantiers
        const newAllChantiers = allChantiers.filter(
          (item) => item.id !== action.payload.id
        )
        state.chantiers = newAllChantiers

        const chantiersOptions = newAllChantiers.map((chantier) => {
          const label = `${chantier.client} (${chantier.reference}) ${chantier.num_commande}`
          return { value: chantier.id, label }
        })
        state.chantiersOptions = chantiersOptions
        const getlistNatures = uniqValuesToOptions(newAllChantiers, 'nature')
        const getlistEtats = uniqValuesToOptions(newAllChantiers, 'etat')
        const getlistClients = uniqValuesToOptions(newAllChantiers, 'client')
        state.naturesOptions = getlistNatures
        state.etatsOptions = getlistEtats
        state.clientsOptions = getlistClients
      })
      .addCase(deleteChantier.rejected, (state, action) => {
        console.log('Erreur deleteChantier')
        console.log(action)
      })
      .addCase(deleteEquipe.fulfilled, (state, action) => {
        const allEquipes = state.equipes
        const newAllEquipes = allEquipes.filter(
          (item) => item.id !== action.payload.id
        )
        state.equipes = newAllEquipes

        const equipesOptions = newAllEquipes.map((equipe) => {
          const label = equipe.title
          return { value: equipe.id, label }
        })
        state.equipesOptions = equipesOptions
      })
      .addCase(deleteEquipe.rejected, (state, action) => {
        console.log('Erreur deleteEquipe')
        console.log(action)
      })
      .addCase(deleteEmploye.fulfilled, (state, action) => {
        const allEmployes = state.employes
        const newAllEmployes = allEmployes.filter(
          (item) => item.id !== action.payload.id
        )
        state.employes = newAllEmployes

        const employesOptions = newAllEmployes.map((employe) => {
          const label = `${employe.nom.toUpperCase()} ${employe.prenom}`
          return { value: employe.id, label }
        })
        state.employesOptions = employesOptions

        state.absencesEmployes = getAbsences(newAllEmployes)
      })
      .addCase(deleteEmploye.rejected, (state, action) => {
        console.log('Erreur deleteEmploye')
        console.log(action)
      })
      .addCase(deleteMateriel.fulfilled, (state, action) => {
        const allMateriels = state.materiels
        const newAllMateriels = allMateriels.filter(
          (item) => item.id !== action.payload.id
        )
        state.materiels = newAllMateriels

        const getlistCategories = uniqValuesToOptions(
          newAllMateriels,
          'categorie'
        )

        state.categoriesOptions = getlistCategories
        state.absencesMateriels = getAbsences(newAllMateriels)
      })
      .addCase(deleteMateriel.rejected, (state, action) => {
        console.log('Erreur deleteMateriel')
        console.log(action)
      })
      .addCase(deleteMemo.fulfilled, (state, action) => {
        const allMemos = state.memos
        const newAllMemos = allMemos.filter(
          (item) => item.id !== action.payload.id
        )

        state.memos = newAllMemos
      })
      .addCase(deleteMemo.rejected, (state, action) => {
        console.log('Erreur deleteMemo')
        console.log(action)
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.selectedEvent = action.payload
      })
      .addCase(getEvent.rejected, (state) => {
        state.selectedEvent = 'error'
      })
  }
})

// on extrait les actions et le reducer
const { actions } = appCalendrierSlice
// on export chaque action individuellement
export const {
  reloadAllData,
  registerSocket,
  socketAddPost,
  socketUpdatePost,
  socketDeletePost
} = actions

export default appCalendrierSlice.reducer
