// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import calendrier from './calendrier'

const rootReducer = {
  auth,
  navbar,
  layout,
  calendrier
}

export default rootReducer
