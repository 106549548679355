// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const { jwt } = useJwt({
  loginEndpoint:
    'https://wp.app.pyweb.fr/valfloregestion/wp-json/api-bearer-auth/v1/login',
  refreshEndpoint:
    'https://wp.app.pyweb.fr/valfloregestion/wp-json/api-bearer-auth/v1/tokens/refresh',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageExpireTokenKeyName: 'expireToken'
})

export default jwt
