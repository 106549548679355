/* eslint-disable multiline-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-confusing-arrow */

import axios from "axios"

// ** Store & Actions
import useJwt from "@src/auth/jwt/useJwt"
// import axios from 'axios'
// import themeConfig from '@configs/themeConfig'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

// ** Retourne le numéro de semaine
export const getWeekNumber = (date) => {
  date = new Date(+date) // Copy date so don't modify original.
  date.setHours(0, 0, 0, 0) // Reset hours.
  date.setDate(date.getDate() + 4 - (date.getDay() || 7)) // Set to nearest Thursday: current date + 4 - current day number and make Sunday's day number 7
  const yearStart = new Date(date.getFullYear(), 0, 1) // Get first day of year
  const weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7) // Calculate full weeks to nearest Thursday

  //console.log(weekNo)

  if (weekNo < 53) {
    return weekNo
  } else {
    return weekNo - 52
  }
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  const accessToken =
    localStorage.getItem("userData") &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  const expireToken =
    localStorage.getItem("userData") &&
    localStorage.getItem(useJwt.jwtConfig.storageExpireTokenKeyName)

  const today = new Date()
  const expireTokenDate = new Date(expireToken)

  if (accessToken && expireToken) {
    if (expireTokenDate < today) {
      // console.log('token périmé')
      return null
    } else {
      // console.log('token encore valide')
      return (
        localStorage.getItem("userData") && localStorage.getItem("userData")
      )
    }
  } else {
    return null
  }
}
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/"
  if (userRole === "client") return "/access-control"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

export const selectThemeColorsSecondary = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#ffc720", // for option hover bg-color
    primary: "#ffc107", // for selected option bg-color
    neutral10: "#ffc107", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})
export const selectThemeColorsSuccess = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#28c76f", // for option hover bg-color
    primary: "#07bc0c", // for selected option bg-color
    neutral10: "#07bc0c", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

export const getAllCptRequest = async (type) => {
  const cpt = type
  const perpage = 100
  let npage = 1
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}?page=${npage}&per_page=${perpage}&_fields=id,title,acf`
  const result = await axios.get(url)
  const data = result.data

  const totalPages = result.headers["x-wp-totalpages"]

  let response = data

  npage++
  while (npage <= totalPages) {
    const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}?page=${npage}&per_page=${perpage}&_fields=id,title,acf`
    const { data } = await axios.get(url)
    response = [...response, ...data]
    npage++
  }

  const postsClean = response.map(({ id, title, acf }) => {
    if (cpt === "employes" || cpt === "materiels") {
      acf.absences = acf.absences ? JSON.parse(acf.absences) : []
    }

    if (cpt === "events") {
      acf.employes = acf.employes ? JSON.parse(acf.employes) : []
      acf.materiels = acf.materiels ? JSON.parse(acf.materiels) : []
      acf.heures_employes = acf.heures_employes
        ? JSON.parse(acf.heures_employes)
        : []
    }

    if (cpt === "equipes") {
      acf.employes = acf.employes ? JSON.parse(acf.employes) : []
      acf.couleur = acf.couleur ? JSON.parse(acf.couleur) : ""
    }

    return {
      id: parseInt(id),
      title: title.rendered,
      ...acf
    }
  })

  return postsClean
}

export const getAllCptRequestSearch = async (args) => {
  const cpt = args.type
  const search = args.search
  const perpage = 100
  let npage = 1
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}?page=${npage}&per_page=${perpage}&search=${search}&_fields=id,title,acf`
  const result = await axios.get(url)
  const data = result.data

  const totalPages = result.headers["x-wp-totalpages"]

  let response = data

  npage++
  while (npage <= totalPages) {
    const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}?page=${npage}&per_page=${perpage}&search=${search}&_fields=id,title,acf`
    const { data } = await axios.get(url)
    response = [...response, ...data]
    npage++
  }

  const postsClean = response.map(({ id, title, acf }) => ({
    id,
    title: title.rendered,
    ...acf
  }))

  return postsClean
}

export const getOption = async (args) => {
  const { key } = args

  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/app/v1/getoption?key=${key}`
  const result = await axios.get(url)
  const { data } = result

  return data
}

export const updateOption = async (args) => {
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/app/v1/updateoption`
  const result = await axios.post(url, args)
  const { data } = result

  return data
}

export const getPost = async (args) => {
  const { id, cpt } = args

  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}/${id}`
  const result = await axios.get(url)
  const { data } = result

  const { title, acf } = data
  post = {
    id: parseInt(id),
    title: title.rendered,
    ...acf
  }

  return post
}

export const addPost = async (args) => {
  const { post, cpt } = args
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}`
  const result = await axios.post(url, post)
  const { data } = result
  const { id, title, acf } = data

  const newPost = {
    id: parseInt(id),
    title: title.rendered,
    ...acf
  }

  return newPost
}

export const updatePost = async (args) => {
  const { id, post, cpt } = args
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}/${id}`
  const result = await axios.post(url, post)
  const { data } = result
  const { title, acf } = data

  const updatedPost = {
    id: parseInt(id),
    title: title.rendered,
    ...acf
  }

  return updatedPost
}

export const deletePost = async (args) => {
  const { id, cpt } = args
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/wp/v2/${cpt}/${id}`
  const result = await axios.delete(url)
  const { data } = result
  const { title, acf } = data

  const deletedPost = {
    id: parseInt(id),
    title: title.rendered,
    ...acf
  }

  return deletedPost
}

export const getAllMetaValues = async (args) => {
  const { type, key } = args
  const url = `https://wp.app.pyweb.fr/valfloregestion/wp-json/app/v1/getmeta?type=${type}&key=${key}`
  const { data } = await axios.get(url)

  return data
}

export const paginate = (array, pageIndex, pageSize) => {
  const first = pageIndex * pageSize
  let last = pageIndex * pageSize
  last = last + pageSize
  return array.filter((_, index) => {
    return first <= index && index < last
  })
}

export const uniqValues = (array, field) => {
  return array.reduce((accumulator, current) => {
    if (!accumulator.includes(current[field]) && current[field]) {
      accumulator.push(current[field])
    }

    return accumulator
  }, [])
}

export const uniqValuesToOptions = (array, field) => {
  let result = array.reduce((accumulator, current) => {
    if (!accumulator.includes(current[field]) && current[field]) {
      accumulator.push(current[field])
    }

    return accumulator
  }, [])

  result = result.map((val) => {
    return { value: val, label: val }
  })
  //result.unshift({ value: '', label: '-' })

  return result
}

export const valuesToOptions = (array) => {
  const arrayToFilter = array

  if (arrayToFilter.length > 0) {
    const result = arrayToFilter.map((val) => {
      return { value: val, label: val }
    })
    // result.unshift({ value: '', label: '-' })

    return result
  }

  return []
}

const getValue = (value) => {
  return typeof value === "string" ? value.toUpperCase() : value
}

export const filterArray = (array, filters) => {
  const arrayToFilter = array
  const filterKeys = Object.keys(filters)

  return arrayToFilter.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores an empty filter
      if (!filters[key].length) return true
      if (filters[key] === "") return true

      return filters[key].find(
        (filter) => getValue(filter) === getValue(item[key])
      )
    })
  })
}

export const searchInArray = (arr = [], q = "") => {
  if (arr.length > 0 && q) {
    const search = q
    const res = arr.filter((obj) =>
      Object.values(obj).some((val) => {
        const result = val
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase())
        return result !== -1
      })
    )

    return res
  } else if (q === "") {
    return arr
  } else {
    return []
  }
}

export const sortByKey = (array, key, sort) => {
  const sortedArray = array.slice()

  // return sortedArray
  return sortedArray.sort((a, b) => {
    const valA = a
    const valB = b

    let x = valA[key]
    let y = valB[key]

    if (key.includes("date")) {
      x = x.split("/").reverse().join("")
      y = y.split("/").reverse().join("")
    }

    if (sort === "asc") return x < y ? -1 : x > y ? 1 : 0
    if (sort === "desc") return x > y ? -1 : x < y ? 1 : 0
  })
}

export const getFirstDayOfWeek = (d) => {
  // 👇️ clone date object, so we don't mutate it
  const date = new Date(d)
  const day = date.getDay() // 👉️ get day of week

  // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)

  return new Date(date.setDate(diff))
}

export const getLastDayOfWeek = (d) => {
  // 👇️ clone date object, so we don't mutate it
  const date = new Date(d)
  const first = date.getDate() - date.getDay() + 1
  const last = first + 4
  return new Date(date.setDate(last))
}

export const filterArrayBetween = (array, filters) => {
  const arrayToFilter = array
  const { dateRepere, key } = filters

  const firstDay = getFirstDayOfWeek(dateRepere)
  const firstDayString = firstDay
    .toLocaleString("fr", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit"
    })
    .split("/")
    .reverse()
    .join("")
  const lastDay = getLastDayOfWeek(dateRepere)
  const lastDayString = lastDay
    .toLocaleString("fr", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit"
    })
    .split("/")
    .reverse()
    .join("")

  const result = arrayToFilter.filter((item) => {
    return (
      parseInt(item[key]) >= parseInt(firstDayString) &&
      parseInt(item[key]) <= parseInt(lastDayString)
    )
  })

  return result
}

export const getObject = (arr, searchId) => {
  const arrayToFilter = arr
  const result = arrayToFilter.find((obj) => obj.id === parseInt(searchId))

  return result
}

export const strToArray = (str, sep = ",") => {
  return str ? str.split(sep) : []
}

export const ucFirst = (str = "") => {
  if (str.length > 0) {
    return str[0].toUpperCase() + str.substring(1)
  } else {
    return str
  }
}

export const convertToDate = (dateString) => {
  //  Convert a "dd/MM/yyyy" string into a Date object
  const d = dateString.split("/")
  const dat = new Date(`${d[2]}/${d[1]}/${d[0]}`)
  return dat
}

export const getObjDay = (valUnix = "") => {
  const date = valUnix ? new Date(valUnix * 1000) : new Date()
  const unix = valUnix ? valUnix : new Date().getTime() / 1000

  const objDay = {
    date,
    unix,
    dateYmd: date
      .toLocaleString("fr", {
        year: "numeric",
        day: "2-digit",
        month: "2-digit"
      })
      .split("/")
      .reverse()
      .join(""),
    datedmY: date.toLocaleString("fr", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }),
    dateDay: date.toLocaleString("fr", {
      day: "2-digit"
    }),
    dateMonth: date.toLocaleString("fr", {
      month: "2-digit"
    }),
    dateString: date.toLocaleString("fr", {
      day: "numeric",
      month: "long",
      year: "numeric"
    }),
    dayString: date.toLocaleString("fr", {
      weekday: "long"
    })
  }

  return objDay
}

export const getWeekEvents = (args) => {
  const { workingWeek, listAllEvents } = args

  const listFilteredEvents = workingWeek.map((day) => {
    const dayMatinEvents = listAllEvents.filter((event) => {
      return event.jour === day.dateYmd && event.creneau === "matin"
    })
    const dayApremEvents = listAllEvents.filter((event) => {
      return event.jour === day.dateYmd && event.creneau === "aprem"
    })
    return {
      day,
      dayMatinEvents,
      dayApremEvents
    }
  })

  return listFilteredEvents
}

export const getListEventsByDay = (arrayEvents = [], order = "asc") => {
  const listDates = uniqValues(
    sortByKey(
      arrayEvents.map((event) => {
        const day = getObjDay(event.date)
        return {
          unix: day.unix,
          day
        }
      }),
      "unix",
      order
    ),
    "unix"
  )

  //Liste par jour et créneau de tous les events
  const allEventsByDay = listDates.map((unix) => {
    const dayJourneeEvents = arrayEvents.filter(
      (event) => event.date === unix && event.creneau === "journee"
    )

    const dayMatinEvents = arrayEvents.filter(
      (event) => event.date === unix && event.creneau === "matin"
    )

    const dayApremEvents = arrayEvents.filter(
      (event) => event.date === unix && event.creneau === "aprem"
    )

    const jour = getObjDay(unix)

    return {
      date: unix,
      jour,
      dayJourneeEvents,
      dayMatinEvents,
      dayApremEvents
    }
  })

  return allEventsByDay
}

export const getListNextEventsByDay = (arrayEvents = []) => {
  const listDates = uniqValues(
    sortByKey(
      arrayEvents.map((event) => {
        return {
          date: new Date(event.date * 1000).toISOString().split("T")[0]
        }
      }),
      "date",
      "asc"
    ),
    "date"
  )

  const today = new Date()
  //Liste des dates à venir ayant des events
  const nextDates = listDates.filter(
    (date) => date >= today.toISOString().split("T")[0]
  )
  listDates.reverse()

  // Liste par jour et créneau des events à venir
  const nextEventsByDay = nextDates.map((date) => {
    const dayJourneeEvents = arrayEvents.filter(
      (event) =>
        new Date(event.date * 1000).toISOString().split("T")[0] === date &&
        event.creneau === "journee"
    )

    const dayMatinEvents = arrayEvents.filter(
      (event) =>
        new Date(event.date * 1000).toISOString().split("T")[0] === date &&
        event.creneau === "matin"
    )

    const dayApremEvents = arrayEvents.filter(
      (event) =>
        new Date(event.date * 1000).toISOString().split("T")[0] === date &&
        event.creneau === "aprem"
    )

    let jour = new Date(date)
    jour = ucFirst(
      jour.toLocaleString("fr", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
      })
    )

    return { date, jour, dayJourneeEvents, dayMatinEvents, dayApremEvents }
  })

  return nextEventsByDay
}

export const filterChantiers = (params) => {
  const {
    chantiers,
    events,
    client,
    nature,
    etat,
    sort,
    sortColumn,
    q,
    planifies
  } = params

  let result = planifies
    ? chantiers.filter((chantier) => {
        const chantierEvents = events.filter(
          (event) => event.chantier === chantier.id
        )

        return chantierEvents.length === 0
      })
    : chantiers

  result = searchInArray(result, q)

  if (nature || etat || client) {
    const filters = {}
    if (etat) filters.etat = [etat]
    if (nature) filters.nature = [nature]
    if (client) filters.client = [client]

    result = filterArray(result, filters)
  }

  result = sortByKey(result, sortColumn, sort)

  return result
}

export const filterEvents = (params) => {
  const { events, etat, imperatif, equipe } = params

  let result = events

  if (etat || imperatif || equipe) {
    const filters = {}
    if (etat) filters.etat = [etat.value]
    if (imperatif) filters.imperatif = [imperatif.value]
    if (equipe) filters.equipe = [equipe.value]

    result = filterArray(result, filters)
  }

  return result
}

export const filterEmployes = (params) => {
  const { employes, sort, sortColumn, q } = params

  let result = employes
  result = searchInArray(result, q)

  result = sortByKey(result, sortColumn, sort)

  return result
}

export const filterEquipes = (params) => {
  const { equipes, sort, sortColumn, q } = params

  let result = equipes
  result = searchInArray(result, q)

  result = sortByKey(result, sortColumn, sort)

  return result
}

export const filterMateriels = (params) => {
  const { materiels, categorie, sort, sortColumn, q } = params

  let result = materiels
  result = searchInArray(result, q)

  if (categorie) {
    const filters = {}
    if (categorie) filters.categorie = [categorie]

    result = filterArray(result, filters)
  }

  result = sortByKey(result, sortColumn, sort)

  return result
}

export const getNextStringId = (str) => {
  let index = str.length - 1
  let baseCode = str.charCodeAt(index)
  do {
    baseCode = str.charCodeAt(index)
    const strArr = str.split("")
    if (strArr[index] === "Z") {
      strArr[index] = "A"
      if (index === 0) {
        strArr.unshift("A")
      }
    } else {
      strArr[index] = String.fromCharCode(baseCode + 1)
    }
    str = strArr.join("")
    index--
  } while (baseCode === 90)
  return str
}

export const getAbsences = (posts) => {
  const result = {}

  posts.map((post) => {
    if (post.absences && post.absences.length > 0) {
      const absences = post.absences
      absences.map((absence) => {
        switch (absence.type) {
          case "single":
            const day = getObjDay(absence.start)

            result[day.dateYmd] = result[day.dateYmd]
              ? [...result[day.dateYmd], post.id]
              : [post.id]
            break
          case "multi":
            const start = new Date(absence.start * 1000)
            const end = new Date(absence.end * 1000)

            let loop = new Date(start)
            while (loop <= end) {
              const day = getObjDay(loop.getTime() / 1000)

              result[day.dateYmd] = result[day.dateYmd]
                ? [...result[day.dateYmd], post.id]
                : [post.id]

              const newDate = loop.setDate(loop.getDate() + 1)
              loop = new Date(newDate)
            }

            break
        }
      })
    }
  })

  return result
}

export const getAbsencesUnix = (posts) => {
  const result = {}

  posts.map((post) => {
    if (post.absences && post.absences.length > 0) {
      const absences = post.absences
      absences.map((absence) => {
        switch (absence.type) {
          case "single":
            const day = getObjDay(absence.start)

            result[day.unix] = result[day.unix]
              ? [...result[day.unix], post.id]
              : [post.id]
            break
          case "multi":
            const start = new Date(absence.start * 1000)
            const end = new Date(absence.end * 1000)

            let loop = new Date(start)
            while (loop <= end) {
              const day = getObjDay(loop.getTime() / 1000)

              result[day.unix] = result[day.unix]
                ? [...result[day.unix], post.id]
                : [post.id]

              const newDate = loop.setDate(loop.getDate() + 1)
              loop = new Date(newDate)
            }

            break
        }
      })
    }
  })

  return result
}
